/* eslint-disable consistent-return */
import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { Input, Steps, StyledButton } from '../../../components/simple';
import { firstStepValues } from '../../../helpers/inputValues';
import styles from './styles.module.scss';
import { get, patch, post, put } from '../../../services/API';
import { steps } from '../../../helpers/stepsValues';
import { authorization } from '../../../helpers/authentication';
import { newAgreementStep2 } from '../../../router/routes';
import { firstStepValidationSchema, firstStepInitialValues } from '../../../helpers/formikValues';
import useMultiLang from '../../../localization/useMultiLang';

const NewAgreementFirstStep = () => {
  const { t, getCurrentLanguage } = useMultiLang();
  const [isSending, setIsSending] = useState(false);
  const [errors, setErrors] = useState('');
  const [agreement, setAgreement] = useState(null);

  const quote = localStorage.getItem('quoteId');
  const companyId = localStorage.getItem('companyId');
  const navigate = useNavigate();

  const handleError = (entity) => {
    setErrors(entity.data.name[0]);
    setIsSending(false);
  };

  const formik = useFormik({
    initialValues: firstStepInitialValues,
    validationSchema: firstStepValidationSchema,

    onSubmit: async (values) => {
      setIsSending(true);

      const setError = (res) => {
        setIsSending(false);
        Object.keys(res.data).forEach((field) => {
          formik.setFieldError(field, res.data[field][0]);
        });
      };

      const requestData = async () => {
        if (companyId) {
          try {
            const data = await patch(`/clients/company/${companyId}`, values, authorization());
            if (data.status !== 200) {
              setError(data);
              return;
            }

            const quotes = await put(
              `/quotes/quotes/${quote}`,
              {
                ...values,
                company_id: companyId,
                manager_id: localStorage.getItem('userId'),
                valid_till: values.expire,
              },
              authorization(),
            );

            if (quotes.status !== 200) {
              setError(quotes);
              return;
            }

            if (data.status === 200) return navigate(newAgreementStep2);
          } catch (error) {
            setErrors('Failed to update company or quote details.');
            return;
          }
        }

        const company = await post('/clients/company', values, authorization());

        if (company.status !== 201) {
          setError(company);
          return;
        }

        const agreementData = await post(
          '/quotes/quotes',
          {
            ...values,
            company_id: company.data.id,
            manager_id: localStorage.getItem('userId'),
            valid_till: values.expire,
            step: 2,
          },
          authorization(),
        );

        if (agreementData.status === 201) {
          localStorage.setItem('companyId', company.data.id);
          localStorage.setItem('quoteId', agreementData.data.id);
          localStorage.setItem('step', '2');
          navigate(newAgreementStep2);
        } else {
          setError(agreementData);
          handleError(agreementData);
        }
      };

      requestData();
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      const { data } = quote && (await get(`/quotes/quotes/${quote}`, authorization()));
      setAgreement(data);
      formik.setFieldValue('name', data.company.name);
      // formik.setFieldValue('country', data.company.country);
      formik.setFieldValue('expire', data.valid_till);
      formik.setFieldValue('organization_number', data.company.organization_number);
      formik.setFieldValue('phone_number', data.company.phone_number);
      formik.setFieldValue('postal_address', data.company.postal_address);
      formik.setFieldValue('zip_code', data.company.zip_code);
      formik.setFieldValue('state', data.company.state);
    };
    if (quote) {
      fetchData();
    }
  }, [quote]);

  return (
    <div>
      <div className={styles.steps}>
        <Steps className={styles.firstStep} steps={steps} agreement={agreement} />
      </div>
      <h3 className={styles.header}>{t('company_info')}:</h3>
      <form onSubmit={formik.handleSubmit} className={styles.form}>
        <div className={styles.inputs}>
          {firstStepValues.map((input) => (
            <div className={styles.inputWrapper} key={input.name}>
              <Input
                name={input.name}
                label={input.label}
                type={input.type}
                onChange={formik.handleChange}
                value={formik.values[input.name]}
                className={`${styles.input} ${
                  formik.touched[input.name] && formik.errors[input.name] && styles.errorInput
                }`}
                labelStyle={styles.label}
                readonly={input.readonly}
                min={input.min}
              />
              {formik.touched[input.name] && formik.errors[input.name] && (
                <div className={styles.error}>{formik.errors[input.name]}</div>
              )}
            </div>
          ))}
        </div>
        <div className={styles.submit}>
          {errors && <div className={styles.error}>{errors}</div>}
          <StyledButton
            color="purple"
            text={isSending ? `${t('sanding')}...` : t('next_page')}
            type="submit"
            className={styles.button}
            disabled={isSending}
          />
        </div>
      </form>
    </div>
  );
};

export default NewAgreementFirstStep;
