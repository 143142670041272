import React from 'react';
import PropTypes from 'prop-types';
import SevenPage from './SevenPage';
import FirstPage from './FirstPage';
import SecondPage from './SecondPage';
import SixPage from './SixPage';
import NinePage from './NinePage';
import TenPage from './TenPage';
import ElevenPage from './ElevenPage';
import TwelvePage from './TwelvePage';
import Discount from './Discount';
import ThirdPage from './ThirdPage';
import FourPage from './FourPage';
import FivePage from './FivePage';
import { serviceName } from '../../../../helpers/tablesValues';

const Summary = ({ data, lang }) => {
  const ramValues = data && data.services.find((item) => item.name === serviceName.PING_RAM);
  const switchValues = data && data.services.find((item) => item.name === serviceName.PING_SWITCH);

  const isDiscount = () =>
    (ramValues?.price_per_month < 3999 && ramValues?.num_services > 0) ||
    (switchValues?.price_per_month < 3999 && switchValues?.num_services > 0);

  const calcRam = 3999 - parseFloat(ramValues?.price_per_month);
  const calcSwitch = 3999 - parseFloat(switchValues?.price_per_month);
  const ramDiscount = ramValues?.num_services > 0 ? calcRam : 0;
  const switchDiscount = switchValues?.num_services > 0 ? calcSwitch : 0;

  const partner = data?.financial_agreement_company;

  return (
    <>
      <FirstPage data={data} lang={lang} />

      <SecondPage data={data} lang={lang} />

      <ThirdPage data={data} lang={lang} />

      <FourPage data={data} lang={lang} />

      <FivePage data={data} lang={lang} />

      <SixPage data={data} lang={lang} />

      <SevenPage data={data} lang={lang} />

      {isDiscount() && (
        <Discount
          data={data}
          ramValues={ramValues}
          switchValues={switchValues}
          ramDiscount={ramDiscount}
          switchDiscount={switchDiscount}
        />
      )}

      <NinePage data={data} lang={lang} partner={partner} />

      {/* {lang === 'en' ? (
        <>
          <TenPage data={data} lang={lang} />

          <ElevenPage data={data} lang={lang} />

          <TwelvePage data={data} lang={lang} />
        </>
      ) : null} */}
    </>
  );
};

Summary.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array, PropTypes.objectOf(PropTypes.shape)]),
  lang: PropTypes.string,
};

Summary.defaultProps = {
  data: null,
  lang: 'sv',
};

export default Summary;
