import React from 'react';
import PropTypes from 'prop-types';
import { Page, Image, Text, View, StyleSheet, Link } from '@react-pdf/renderer';
import logo from '../../../../assets/images/Logo.png';
import { summaryPdfStyles } from '../../../../helpers/pdfCommonStyles';

import replacePlaceholders from '../../../../helpers/replacePlaceholders';
import { getTranslations } from '../translations';
import { SVEA } from '../../../../helpers/partner';

const styles = StyleSheet.create({
  ...summaryPdfStyles,
  title: {
    fontFamily: 'Como-Bold',
    fontSize: 30,
    marginTop: 20,
    marginBottom: 10,
  },
  info: {
    fontFamily: 'Como-Bold',
    fontSize: 12,
    lineHeight: 1.5,
  },
  sectionTitle: {
    fontFamily: 'Como-Bold',
    fontSize: 14,
    marginTop: 10,
  },
  customerInfo: {
    display: 'flex',
    flexDirection: 'row',
    width: 600,
  },
  items: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: 10,
    width: 350,
    marginTop: 5,
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    padding: '2px 0',
  },
  itemTitle: {
    fontFamily: 'Como-Bold',
  },
});

const NinePage = ({ data, lang, partner }) => {
  const { translations, t, language } = getTranslations('ninePage', lang);

  let href;
  let link;

  if (partner === SVEA && language === 'sv') {
    href = 'https://frontdesk.se/pingaway/av20240709/s';
    link = 'www.frontdesk.se/pingaway/av20240709/s';
  } else if (language === 'sv') {
    href = 'https://frontdesk.se/pingaway/av20240709/';
    link = 'www.frontdesk.se/pingaway/av20240709';
  } else if (lang === 'en') {
    href = 'https://frontdesk.se/gt20240709/';
    link = 'https://frontdesk.se/gt20240709/';
  } else {
    href = 'https://pingaway.no/gv20240709/s';
    link = 'https://pingaway.no/gv20240709/s';
  }

  return (
    <Page style={styles.page}>
      <View style={styles.container}>
        <View style={styles.logo}>
          <Image src={logo} />
          <View style={styles.agreementNumberBlock}>
            <Text style={styles.agreementNumber}>{data?.agreement_id}</Text>
            <Text style={styles.agreementNumber}>{data?.created_at}</Text>
          </View>
        </View>

        <View style={styles.content}>
          <Text style={styles.title}>{translations.title}</Text>
          <Text style={styles.info}>
            {translations.description
              .replace('{{company_name}}', data?.company.name)
              .replace('{{agreement_id}}', data?.agreement_id)
              .replace('{{created_at}}', data?.created_at)}
          </Text>

          <Text style={styles.sectionTitle}>{translations.customer}</Text>
          <View style={styles.customerInfo}>
            <View style={styles.items}>
              <View style={styles.item}>
                <Text style={styles.itemTitle}>{translations.company_name} </Text>
                <Text>{data?.company.name}</Text>
              </View>
              <View style={styles.item}>
                <Text style={styles.itemTitle}>{translations.organization_number} </Text>
                <Text>{data?.company.organization_number}</Text>
              </View>
              <View style={styles.item}>
                <Text style={styles.itemTitle}>{translations.postal_address} </Text>
                <Text>{data?.company.postal_address}</Text>
              </View>
              <View style={styles.item}>
                <Text style={styles.itemTitle}>{translations.zip_code} </Text>
                <Text>{`${data?.company.zip_code} ${data?.company.state}`}</Text>
              </View>

              <View style={styles.item}>
                <Text style={styles.itemTitle}>{translations.phone_number} </Text>
                <Text>{data?.company?.signers?.[0]?.phone_number ?? ''}</Text>
              </View>
              <View style={styles.item}>
                <Text style={styles.itemTitle}>{translations.email} </Text>
                <Text>{data?.company?.signers?.[0]?.email ?? ''}</Text>
              </View>
            </View>
            <View style={styles.items}>
              <View style={styles.item}>
                <Text style={styles.itemTitle}>{translations.contact_person} </Text>
                <Text>{data?.company?.telephone_manager?.name}</Text>
              </View>
              <View style={styles.item}>
                <Text style={styles.itemTitle}>{translations.email} </Text>
                <Text>{data?.company?.telephone_manager?.email}</Text>
              </View>
              <View style={styles.item}>
                <Text style={styles.itemTitle}>{translations.phone_number} </Text>
                <Text>{data?.company?.telephone_manager?.phone_number}</Text>
              </View>
            </View>
          </View>

          <Text style={{ ...styles.sectionTitle, marginTop: 20 }}>{translations.other}</Text>
          <View style={{ display: 'flex', flexDirection: 'column', fontSize: 12 }}>
            <View style={{ display: 'flex', flexDirection: 'row', padding: '5px 0' }}>
              <Text style={{ width: 120 }}>{translations.traffic_price_list}</Text>
              <Text>{translations.price_list}</Text>
            </View>
            <View style={{ display: 'flex', flexDirection: 'row', padding: '5px 0' }}>
              <Text style={{ width: 120 }}>{translations.startup_cost}</Text>
              <Text>
                {parseFloat(data?.one_time_fee)} {translations.sek}
              </Text>
            </View>
            <View style={{ display: 'flex', flexDirection: 'row', padding: '5px 0' }}>
              <Text style={{ width: 120 }}>{translations.monthly_fee}</Text>
              <Text>
                {parseFloat(data?.monthly_cost)} {translations.sek}
              </Text>
            </View>
          </View>

          <Text style={{ fontSize: 10, margin: '10px 0', lineHeight: 1.5 }}>
            {replacePlaceholders(translations.info, {
              link: (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <Link src={href} key={1} style={{ color: '#10225c', textDecoration: 'none' }}>
                  {link}
                </Link>
              ),
            })}
          </Text>
        </View>

        <Text style={{ width: '100%', borderBottom: 1, marginTop: 10 }}> </Text>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '80%',
            margin: '0 auto',
            fontSize: 14,
            fontFamily: 'Como-Bold',
            marginTop: 10,
          }}
        >
          <Text>{translations.signature}</Text>
          <Text>{translations.location_date}</Text>
        </View>

        <Text style={{ width: '100%', borderBottom: 1, marginTop: 20 }}> </Text>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '80%',
            margin: '0 auto',
            fontSize: 14,
            fontFamily: 'Como-Bold',
            marginTop: 10,
          }}
        >
          <Text>{translations.name_clarification}</Text>
        </View>
      </View>
      <View style={styles.footer}>
        <View style={{ ...styles.footerName, marginBottom: 3 }}>
          <Text style={{ fontFamily: 'Como-Bold' }}>{t.common.offer} </Text>
          <Text style={{ fontFamily: 'Como-SemiBold' }}>{data?.company.name}</Text>
        </View>
        <Text style={{ fontFamily: 'Como-Medium' }}>{data?.created_at}</Text>
      </View>
    </Page>
  );
};

NinePage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array, PropTypes.objectOf(PropTypes.shape)]),
  lang: PropTypes.string,
  partner: PropTypes.string,
};

NinePage.defaultProps = {
  data: null,
  lang: 'sv',
  partner: '',
};

export default NinePage;
